<template>
  <v-container>
    <v-row>
      <v-col align="center">
        <div class="display-4">Oops!</div>
        <div class="headline mt-8">
          No podemos encontrar la página que estás buscando
        </div>
        <v-btn class="mt-8" to="/issues" color="primary"
          >Regresar al inicio</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Error404'
}
</script>
